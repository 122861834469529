<template>
  <section class="legal-page">
    <BreadcrumbNav :route="this.$route.name"/>
    <PrivacyPolicy v-if="this.$route.path === '/privacy-policy'"/>
    <CookiePolicy v-if="this.$route.path === '/cookie-policy'"/>
    <TermsOfService v-if="this.$route.path === '/terms-of-service'"/>
  </section>
</template>

<script>
import BreadcrumbNav from '@/components/BreadcrumbNav.vue'
import TermsOfService from '@/components/TermsOfService.vue'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'
import CookiePolicy from '@/components/CookiePolicy.vue'

export default {
  name: 'LegalPage',
  components: {
    BreadcrumbNav,
    TermsOfService,
    PrivacyPolicy,
    CookiePolicy
  }
}
</script>

<style scoped lang="scss">
#app.TermsOfService,
#app.PrivacyPolicy,
#app.CookiePolicy {
  .container {
    background: white;
    width: 100%;
    height: auto;
    padding: $margin;
    text-align: left;
    a {
      color: blue;
    }
  }
}

</style>
