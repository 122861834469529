<template>
  <div class="container">
    <h1>Movers & Shakers Foundation <br>Privacy Policy</h1>
    <p>Effective Date: February 19, 2021</p>
    <p>This Privacy Policy explains how information about you is collected, used and disclosed by Movers &amp; Shakers Foundation, also known as Kinfolk Foundation (“Movers &amp; Shakers,” “Kinfolk Foundation”, “Kinfolk” “we,” “us,” or “our”). This Privacy Policy applies to information we collect when you use our website located at <a href="https://www.moversandshakersnyc.org" target="_blank">www.moversandshakersnyc.org</a> and any other Movers &amp; Shakers websites, apps and online services that link to this Privacy Policy (collectively, the "Services").</p>
    <p>We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and by providing you with additional notice (such as adding a statement to our homepage or sending you an email notification) in advance of the effective date of the applicable changes. We encourage you to review the Privacy Policy whenever you access the Services to stay informed about our information practices and the ways you can help protect your privacy.</p>

    <h2>How We Collect Information From You</h2>
    <h3>Information You Directly Provide to Us</h3>
    <p>We collect information you provide directly to us. For example, we collect information when use our Services, fill out a form, make a donation, send us an email, sign up to receive updates, request information, sign a petition, sign up as a volunteer, communicate with us via third party social media sites, or otherwise communicate with us. The types of information we collect through our website may include your name, address, phone number, email address, mobile number, credit card information, and other contact or identifying information you may choose to provide. The types of information we collect through the Kinfolk app may include your usage information. We do not collect personal information in our Kinfolk app.</p>

    <h3>Information We Collect Automatically When You Use the Services</h3>
    <p>When you access or use our Services, we automatically collect information about you, including:</p>
    <p><em>Log Information</em>: We log information about your use of the Services, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to our Services.</p>
    <p><em>Device Information</em>: We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers and mobile network information.</p>
    <p><em>Information Collected by Cookies and Other Tracking Technologies</em>: We use various technologies to collect information, and this may include sending cookies to your computer or mobile device. Cookies are small data files stored on your hard drive or in device memory that helps us to improve our Services and your experience, see which areas and features of our Services are popular and count visits. We may also collect information using web beacons (also known as "tracking pixels"). Web beacons are electronic images that may be used in our Services or emails and help deliver cookies, count visits, understand usage and campaign effectiveness and determine whether an email has been opened and acted upon. For more information about cookies, and how to disable them, please see "Your Choices" below.</p>

    <h3>How We Use of the Information We Collect Through the Services</h3>
    <p>We may use the information we collect about you through the Services for various purposes, including to:</p>
    <ul>
    <li>Provide, maintain and improve our Services;</li>
    <li>Provide and deliver the information you request, process donations and send you related information,   including  confirmations and receipts,</li>
    <li>Send you technical notices, updates, security alerts and support and administrative messages;</li>
    <li>Respond to your emails, comments, questions and requests and provide customer service, request feedback, and otherwise  contact you about your use of the Services;</li>
    <li>Send you newsletters and otherwise provide you with news and information you request or that we think will be of interest   to you, such as sending you information to keep you informed about various campaigns,   candidates, issues, events, resources,  promotions, contests, products and services;</li>
    <li>Link or combine with information we get from others to help understand your needs and provide you with   better service;  and</li>
    <li>Carry out any other purpose for which the information was collected.</li>
    </ul>

    <h3>How We Share the Information We Collect Through the Services</h3>
    <p>We may share information about you as outline below or as otherwise described in this Privacy Policy:</p>
    <p><em>Third Party Vendors and Service Providers</em>. We may share your information with vendors, consultants and other service providers or volunteers who need access to the applicable information to carry out work on our behalf.</p>
    <p><em>Response to Subpoenas or Court Orders or to Protect Rights and to Comply with Our Policies</em>. To the extent permitted by law, we will disclose your information to government authorities or third parties if: (i) required to do so by law, or in response to a subpoena or court order; (ii) we believe in our sole discretion that disclosure is reasonably necessary to protect against fraud, to protect the property or other rights of us or other users, third parties or the public at large; or (iii) we believe that you have abused the Services by using it to attack other systems or to gain unauthorized access to any other system, to engage in spamming or otherwise to violate applicable laws. You should be aware that, following disclosure to any third party, your information may be accessible by others to the extent permitted or required by applicable law.</p>
    <p><em>With Your Consent</em>. We may share your information with third parties with your consent or at your direction, including if we notify you through our Services that the information you provide will be shared in a particular manner and you provide the applicable information.</p>
    <p><em>Aggregate Information</em>. We may also share aggregated or anonymized information that does not directly identify you. While this information will not identify you personally, in some instances the applicable recipients of this aggregate information may be able to combine this aggregate information with other data they have about you, or that they receive from third parties, in a manner that allows them to identify you personally.</p>
    <p>All third party services / SDKs provide the same or equal protection of user data as stated in this privacy policy.</p>

    <h3>Information Collected by Third Parties through Third Party Links and Content</h3>
    <p>The Services may include links to other websites and other content from third party businesses. These third party businesses may use cookies, web beacons or other similar technology to collect information about you. We do not have access to or control over these third parties or the cookies, web beacons or other technology that these third parties may use. We are not responsible for the privacy practices of these third parties or the content on any third party website. You are encouraged to review the privacy policies of the different websites you visit and of the links you may choose to click while on our Services.</p>
    <p><em>Information You Provide When You Make a Donation through the Services</em></p>
    <p>We use two third party service providers, Zelle and the Cash App to process the payment of donations through our website, and any data collection and processing activity in connection with information submit when you made a donation through our website.</p>
    <p><em>Security & Retention</em></p>
    <p>We takes reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.</p>
    <p>Your information will be deleted when they no longer serve one of the above-mentioned purposes but in any event within three (3) years of inactivity in your use of the Site. You may also contact us any time at info@shakers.tech to request that your personal information be deleted.</p>
    <p><em>Special Notice for Users Located Outside of the United States of America</em></p>
    <p>At this time, the Services are only intended to be available to residents of the United States of America and it is not intended to be directed to anyone residing outside of the United States of America. If this current limitation changes, we expect that the Privacy Policy available to non-U.S. residents will reflect the laws and regulations of the territories where the Services is intentionally made available.</p>

    <h4>Your Choices</h4>
    <p><em>Cookies</em>. Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. To find out more about cookies, including how to see what cookies have been set on your device and how to manage and delete them, visit www.allaboutcookies.org. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services.</p>
    <p><em>Promotional Communications</em>. You may opt out of receiving promotional communications from Movers & Shakers by following the instructions in those communications. If you opt out, we may still send you non-promotional communications.</p>

    <h4>Contact Us</h4>
    <p>If you have any questions about this Privacy Policy, please contact us at: <a href="mailto:info@shakers.tech">info@shakers.tech</a>.</p>
    <p>Last Updated: May 5, 2023</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  mounted: function () {
    this.$store.dispatch('setTerm', { term: 'Privacy Policy' })
  }
}
</script>
